<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-4 pt-5 pb-5">
            <h1 class="page-header">Population<sup>1-4</sup></h1>
          </div>
          <div class="cell small-12 medium-2 medium-offset-3 pt-5 pb-5">
            <button class="button expanded blue-active">Select</button>
          </div>
          <div class="cell small-12 medium-3 pt-5 pb-5">
            <router-link to="/population-manual">
              <button class="button expanded blue-ghost">Input manually</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h5>
              Please use the drop-downs to select the geography you wish to model, or enter
              manually.
            </h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-4 pt-5 pb-5">
            <label>Country</label>
            <select v-model="country">
              <option
                v-for="(c, index) in countries"
                :key="`c_${index}`">
                {{ c }}
              </option>
            </select>
          </div>
          <div class="cell small-12 medium-4 pt-5 pb-5">
            <label>Region/Area/Health Board</label>
            <select v-model="region">
              <option
                v-for="(r, index) in regions"
                :key="`r_${index}`">
                {{ r }}
              </option>
            </select>
          </div>
          <div class="cell small-12 medium-4 pt-5 pb-5">
            <label>Organisation</label>
            <select v-model="organisation">
              <option
                v-for="(o, index) in organisations"
                :key="`o_${index}`">
                {{ o }}
                </option>
            </select>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-4 pt-5">
            <h5>Selection</h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x pb-5">
          <div class="cell small-12 medium-4">
            <label>Country</label>
            <input v-model="country" class="fixed" type="text" disabled>
          </div>
          <div class="cell small-12 medium-4">
            <label>Region/Area/Health Board</label>
            <input v-model="region" class="fixed" type="text" disabled>
          </div>
          <div class="cell small-12 medium-4">
            <label>Organisation</label>
            <input v-model="organisation" class="fixed" type="text" disabled>
          </div>
        </div>
        <div class="grid-x grid-padding-x pt-5 pb-5">
          <div class="cell small-12 medium-4">
            <label>Population</label>
            <number v-model="population" v-number="number" class="fixed" type="text" disabled></number>
          </div>
          <div class="cell small-12 medium-2 medium-offset-6 pt-15 text-right">
            <button
              v-if="population === 0"
              class="button expanded disabled">Next</button>
            <button
              v-else
              @click="setPopulation"
              class="button expanded">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'Population',
  components: {
    Header,
  },
  computed: {
    population: {
      get() {
        return this.$store.state.population;
      },
      set(value) {
        this.$store.commit('setPopulation', value);
      },
    },
    hospital: {
      get() {
        return this.$store.state.hospital;
      },
      set(value) {
        this.$store.commit('setHospital', value);
      },
    },
    country: {
      get() {
        return this.$store.state.country;
      },
      set(value) {
        this.$store.commit('setCountry', value);
      },
    },
    region: {
      get() {
        return this.$store.state.region;
      },
      set(value) {
        this.$store.commit('setRegion', value);
      },
    },
    organisation: {
      get() {
        return this.$store.state.organisation;
      },
      set(value) {
        this.$store.commit('setOrganisation', value);
      },
    },
  },
  data() {
    return {
      countries: ['England', 'Scotland', 'Northern Ireland', 'Wales'],
      regions: [],
      organisations: [],
      number: {
        separator: ',',
        precision: 2,
        masked: true,
      },
    };
  },
  watch: {
    country: {
      handler(value) {
        if (value === 'England') {
          this.regions = [
            'All England',
            'North East and Yorkshire',
            'North West',
            'Midlands',
            'East of England',
            'London',
            'South East',
            'South West',
          ];
          this.organisations = [];
        }
        if (value === 'Scotland') {
          this.regions = [
            'Scottish Health Board',
          ];
          this.organisations = [];
        }
        if (value === 'Northern Ireland') {
          this.regions = [
            'All Northern Ireland',
            'BHSCT',
            'NHSCT',
            'SEHSCT',
            'SHSCT',
            'WHSCT',
          ];
          this.organisations = [];
        }
        if (value === 'Wales') {
          this.regions = [
            'Welsh Health Board',
          ];
          this.organisations = [];
        }
      },
      immediate: true,
    },
    region: {
      handler(value) {
        if (this.country === 'England' && value === 'All England') {
          this.organisations = [
            'All England',
          ];
        }
        if (this.country === 'England' && value === 'North East and Yorkshire') {
          this.organisations = [
            'North East and Yorkshire (All)',
            'Humber and North Yorkshire ICB',
            'North East and North Cumbria ICB',
            'South Yorkshire ICB',
            'West Yorkshire ICB',
          ];
        }
        if (this.country === 'England' && value === 'North West') {
          this.organisations = [
            'North West (All)',
            'Cheshire and Merseyside ICB',
            'Greater Manchester ICB',
            'Lancashire and South Cumbria ICB',
          ];
        }
        if (this.country === 'England' && value === 'Midlands') {
          this.organisations = [
            'Midlands (All)',
            'Birmingham and Solihull ICB',
            'Black Country ICB',
            'Coventry and Warwickshire ICB',
            'Derby and Derbyshire ICB',
            'Herefordshire and Worcestershire ICB',
            'Leicester, Leicestershire and Rutland ICB',
            'Lincolnshire ICB',
            'Northamptonshire ICB',
            'Nottingham and Nottinghamshire ICB',
            'Shropshire, Telford and Wrekin ICB',
            'Staffordshire and Stoke-on-Trent ICB',
          ];
        }
        if (this.country === 'England' && value === 'East of England') {
          this.organisations = [
            'East of England (All)',
            'Bedfordshire, Luton and Milton Keynes ICB',
            'Cambridgeshire and Peterborough ICB',
            'Hertfordshire and West Essex ICB',
            'Mid and South Essex ICB',
            'Norfolk and Waveney ICB',
            'Suffolk and North East Essex ICB',
          ];
        }
        if (this.country === 'England' && value === 'London') {
          this.organisations = [
            'London (All)',
            'North Central London ICB',
            'North East London ICB',
            'North West London ICB',
            'South East London ICB',
            'South West London ICB',
          ];
        }
        if (this.country === 'England' && value === 'South East') {
          this.organisations = [
            'South East (All)',
            'Buckinghamshire, Oxfordshire and Berkshire West ICB',
            'Frimley ICB',
            'Hampshire and Isle of Wight ICB',
            'Kent and Medway ICB',
            'Surrey Heartlands ICB',
            'Sussex ICB',
          ];
        }
        if (this.country === 'England' && value === 'South West') {
          this.organisations = [
            'South West (All)',
            'Bath and North East Somerset, Swindon and Wiltshire ICB',
            'Bristol, North Somerset and South Gloucestershire ICB',
            'Cornwall and The Isles Of Scilly ICB',
            'Devon ICB',
            'Dorset ICB',
            'Gloucestershire ICB',
            'Somerset ICB',
          ];
        }
        if (this.country === 'Northern Ireland' && value === 'All Northern Ireland') {
          this.organisations = [
            'All Northern Ireland',
          ];
        }
        if (this.country === 'Northern Ireland' && value === 'BHSCT') {
          this.organisations = [
            'Belfast',
          ];
        }
        if (this.country === 'Northern Ireland' && value === 'NHSCT') {
          this.organisations = [
            'Northern',
          ];
        }
        if (this.country === 'Northern Ireland' && value === 'SEHSCT') {
          this.organisations = [
            'South Eastern',
          ];
        }
        if (this.country === 'Northern Ireland' && value === 'SHSCT') {
          this.organisations = [
            'Southern',
          ];
        }
        if (this.country === 'Northern Ireland' && value === 'WHSCT') {
          this.organisations = [
            'Western',
          ];
        }
        if (this.country === 'Scotland' && value === 'Scottish Health Board') {
          this.organisations = [
            'All Scotland',
            'NHS Ayrshire & Arran',
            'NHS Borders',
            'NHS Dumfries & Galloway',
            'NHS Fife',
            'NHS Forth Valley',
            'NHS Grampian',
            'NHS Greater Glasgow & Clyde',
            'NHS Highland',
            'NHS Lanarkshire',
            'NHS Lothian',
            'NHS Orkney',
            'NHS Shetland',
            'NHS Tayside',
            'NHS Western Isles',
          ];
        }
        if (this.country === 'Wales' && value === 'Welsh Health Board') {
          this.organisations = [
            'All Wales',
            'Betsi Cadwaladr University Health Board',
            'Powys Teaching Health Board',
            'Hywel Dda University Health Board',
            'Swansea Bay University Health Board',
            'Cwm Taf Morgannwg University Health Board',
            'Aneurin Bevan University Health Board',
            'Cardiff and Vale University Health Board',
          ];
        }
      },
      immediate: true,
    },
    organisation(value) {
      if (this.country === 'England' && this.region === 'All England' && value === 'All England') { this.population = 61498822; this.hospital = 'All England'; }
      if (this.country === 'England' && this.region === 'North East and Yorkshire' && value === 'North East and Yorkshire (All)') { this.population = 9012300; this.hospital = 'North East and Yorkshire (All)'; }
      if (this.country === 'England' && this.region === 'North East and Yorkshire' && value === 'Humber and North Yorkshire ICB') { this.population = 1771076; this.hospital = 'Humber and North Yorkshire ICB'; }
      if (this.country === 'England' && this.region === 'North East and Yorkshire' && value === 'North East and North Cumbria ICB') { this.population = 3139823; this.hospital = 'North East and North Cumbria ICB'; }
      if (this.country === 'England' && this.region === 'North East and Yorkshire' && value === 'South Yorkshire ICB') { this.population = 1483968; this.hospital = 'South Yorkshire ICB'; }
      if (this.country === 'England' && this.region === 'North East and Yorkshire' && value === 'West Yorkshire ICB') { this.population = 2617433; this.hospital = 'West Yorkshire ICB'; }
      if (this.country === 'England' && this.region === 'North West' && value === 'North West (All)') { this.population = 7671121; this.hospital = 'North West (All)'; }
      if (this.country === 'England' && this.region === 'North West' && value === 'Cheshire and Merseyside ICB') { this.population = 2714167; this.hospital = 'Cheshire and Merseyside ICB'; }
      if (this.country === 'England' && this.region === 'North West' && value === 'Greater Manchester ICB') { this.population = 3146943; this.hospital = 'Greater Manchester ICB'; }
      if (this.country === 'England' && this.region === 'North West' && value === 'Lancashire and South Cumbria ICB') { this.population = 1810011; this.hospital = 'Lancashire and South Cumbria ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Midlands (All)') { this.population = 11578126; this.hospital = 'Midlands (All)'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Birmingham and Solihull ICB') { this.population = 1577949; this.hospital = 'Birmingham and Solihull ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Black Country ICB') { this.population = 1277444; this.hospital = 'Black Country ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Coventry and Warwickshire ICB') { this.population = 1052979; this.hospital = 'Coventry and Warwickshire ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Derby and Derbyshire ICB') { this.population = 1111009; this.hospital = 'Derby and Derbyshire ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Herefordshire and Worcestershire ICB') { this.population = 818249; this.hospital = 'Herefordshire and Worcestershire ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Leicester, Leicestershire and Rutland ICB') { this.population = 1185265; this.hospital = 'Leicester, Leicestershire and Rutland ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Lincolnshire ICB') { this.population = 806534; this.hospital = 'Lincolnshire ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Northamptonshire ICB') { this.population = 814554; this.hospital = 'Northamptonshire ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Nottingham and Nottinghamshire ICB') { this.population = 1240698; this.hospital = 'Nottingham and Nottinghamshire ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Shropshire, Telford and Wrekin ICB') { this.population = 521391; this.hospital = 'Shropshire, Telford and Wrekin ICB'; }
      if (this.country === 'England' && this.region === 'Midlands' && value === 'Staffordshire and Stoke-on-Trent ICB') { this.population = 1172053; this.hospital = 'Staffordshire and Stoke-on-Trent ICB'; }
      if (this.country === 'England' && this.region === 'East of England' && value === 'East of England (All)') { this.population = 7082155; this.hospital = 'East of England (All)'; }
      if (this.country === 'England' && this.region === 'East of England' && value === 'Bedfordshire, Luton and Milton Keynes ICB') { this.population = 1070212; this.hospital = 'Bedfordshire, Luton and Milton Keynes ICB'; }
      if (this.country === 'England' && this.region === 'East of England' && value === 'Cambridgeshire and Peterborough ICB') { this.population = 1008472; this.hospital = 'Cambridgeshire and Peterborough ICB'; }
      if (this.country === 'England' && this.region === 'East of England' && value === 'Hertfordshire and West Essex ICB') { this.population = 1612064; this.hospital = 'Hertfordshire and West Essex ICB'; }
      if (this.country === 'England' && this.region === 'East of England' && value === 'Mid and South Essex ICB') { this.population = 1256523; this.hospital = 'Mid and South Essex ICB'; }
      if (this.country === 'England' && this.region === 'East of England' && value === 'Norfolk and Waveney ICB') { this.population = 1086462; this.hospital = 'Norfolk and Waveney ICB'; }
      if (this.country === 'England' && this.region === 'East of England' && value === 'Suffolk and North East Essex ICB') { this.population = 1048423; this.hospital = 'Suffolk and North East Essex ICB'; }
      if (this.country === 'England' && this.region === 'London' && value === 'London (All)') { this.population = 10579509; this.hospital = 'London (All)'; }
      if (this.country === 'England' && this.region === 'London' && value === 'North Central London ICB') { this.population = 1734061; this.hospital = 'North Central London ICB'; }
      if (this.country === 'England' && this.region === 'London' && value === 'North East London ICB') { this.population = 2342205; this.hospital = 'North East London ICB'; }
      if (this.country === 'England' && this.region === 'London' && value === 'North West London ICB') { this.population = 2725166; this.hospital = 'North West London ICB'; }
      if (this.country === 'England' && this.region === 'London' && value === 'South East London ICB') { this.population = 2051571; this.hospital = 'South East London ICB'; }
      if (this.country === 'England' && this.region === 'London' && value === 'South West London ICB') { this.population = 1726507; this.hospital = 'South West London ICB'; }
      if (this.country === 'England' && this.region === 'South East' && value === 'South East (All)') { this.population = 9569166; this.hospital = 'South East (All)'; }
      if (this.country === 'England' && this.region === 'South East' && value === 'Buckinghamshire, Oxfordshire and Berkshire West ICB') { this.population = 1935027; this.hospital = 'Buckinghamshire, Oxfordshire and Berkshire West ICB'; }
      if (this.country === 'England' && this.region === 'South East' && value === 'Frimley ICB') { this.population = 808083; this.hospital = 'Frimley ICB'; }
      if (this.country === 'England' && this.region === 'South East' && value === 'Hampshire and Isle of Wight ICB') { this.population = 1916638; this.hospital = 'Hampshire and Isle of Wight ICB'; }
      if (this.country === 'England' && this.region === 'South East' && value === 'Kent and Medway ICB') { this.population = 1966153; this.hospital = 'Kent and Medway ICB'; }
      if (this.country === 'England' && this.region === 'South East' && value === 'Surrey Heartlands ICB') { this.population = 1122802; this.hospital = 'Surrey Heartlands ICB'; }
      if (this.country === 'England' && this.region === 'South East' && value === 'Sussex ICB') { this.population = 1820464; this.hospital = 'Sussex ICB'; }
      if (this.country === 'England' && this.region === 'South West' && value === 'South West (All)') { this.population = 6006444; this.hospital = 'South West (All)'; }
      if (this.country === 'England' && this.region === 'South West' && value === 'Bath and North East Somerset, Swindon and Wiltshire ICB') { this.population = 980516; this.hospital = 'Bath and North East Somerset, Swindon and Wiltshire ICB'; }
      if (this.country === 'England' && this.region === 'South West' && value === 'Bristol, North Somerset and South Gloucestershire ICB') { this.population = 1057832; this.hospital = 'Bristol, North Somerset and South Gloucestershire ICB'; }
      if (this.country === 'England' && this.region === 'South West' && value === 'Cornwall and The Isles Of Scilly ICB') { this.population = 601786; this.hospital = 'Cornwall and The Isles Of Scilly ICB'; }
      if (this.country === 'England' && this.region === 'South West' && value === 'Devon ICB') { this.population = 1273431; this.hospital = 'Devon ICB'; }
      if (this.country === 'England' && this.region === 'South West' && value === 'Dorset ICB') { this.population = 819184; this.hospital = 'Dorset ICB'; }
      if (this.country === 'England' && this.region === 'South West' && value === 'Gloucestershire ICB') { this.population = 676860; this.hospital = 'Gloucestershire ICB'; }
      if (this.country === 'England' && this.region === 'South West' && value === 'Somerset ICB') { this.population = 596836; this.hospital = 'Somerset ICB'; }
      if (this.country === 'Northern Ireland' && this.region === 'All Northern Ireland' && value === 'All Northern Ireland') { this.population = 1895500; this.hospital = 'All Northern Ireland'; }
      if (this.country === 'Northern Ireland' && this.region === 'BHSCT' && value === 'Belfast') { this.population = 359200; this.hospital = 'Belfast'; }
      if (this.country === 'Northern Ireland' && this.region === 'NHSCT' && value === 'Northern') { this.population = 480200; this.hospital = 'Northern'; }
      if (this.country === 'Northern Ireland' && this.region === 'SEHSCT' && value === 'South Eastern') { this.population = 364200; this.hospital = 'South Eastern'; }
      if (this.country === 'Northern Ireland' && this.region === 'SHSCT' && value === 'Southern') { this.population = 388700; this.hospital = 'Southern'; }
      if (this.country === 'Northern Ireland' && this.region === 'WHSCT' && value === 'Western') { this.population = 303200; this.hospital = 'Western'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'All Scotland') { this.population = 5485890; this.hospital = 'All Scotland'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Ayrshire & Arran') { this.population = 366993; this.hospital = 'NHS Ayrshire & Arran'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Borders') { this.population = 115769; this.hospital = 'NHS Borders'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Dumfries & Galloway') { this.population = 147316; this.hospital = 'NHS Dumfries & Galloway'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Fife') { this.population = 371901; this.hospital = 'NHS Fife'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Forth Valley') { this.population = 310215; this.hospital = 'NHS Forth Valley'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Grampian') { this.population = 588165; this.hospital = 'NHS Grampian'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Greater Glasgow & Clyde') { this.population = 1187525; this.hospital = 'NHS Greater Glasgow & Clyde'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Highland') { this.population = 320809; this.hospital = 'NHS Highland'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Lanarkshire') { this.population = 662731; this.hospital = 'NHS Lanarkshire'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Lothian') { this.population = 926160; this.hospital = 'NHS Lothian'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Orkney') { this.population = 22318; this.hospital = 'NHS Orkney'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Shetland') { this.population = 22982; this.hospital = 'NHS Shetland'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Tayside') { this.population = 416803; this.hospital = 'NHS Tayside'; }
      if (this.country === 'Scotland' && this.region === 'Scottish Health Board' && value === 'NHS Western Isles') { this.population = 26203; this.hospital = 'NHS Western Isles'; }
      if (this.country === 'Wales' && this.region === 'Welsh Health Board' && value === 'All Wales') { this.population = 3169586; this.hospital = 'All Wales'; }
      if (this.country === 'Wales' && this.region === 'Welsh Health Board' && value === 'Betsi Cadwaladr University Health Board') { this.population = 703361; this.hospital = 'Betsi Cadwaladr University Health Board'; }
      if (this.country === 'Wales' && this.region === 'Welsh Health Board' && value === 'Powys Teaching Health Board') { this.population = 133030; this.hospital = 'Powys Teaching Health Board'; }
      if (this.country === 'Wales' && this.region === 'Welsh Health Board' && value === 'Hywel Dda University Health Board') { this.population = 389719; this.hospital = 'Hywel Dda University Health Board'; }
      if (this.country === 'Wales' && this.region === 'Welsh Health Board' && value === 'Swansea Bay University Health Board') { this.population = 390949; this.hospital = 'Swansea Bay University Health Board'; }
      if (this.country === 'Wales' && this.region === 'Welsh Health Board' && value === 'Cwm Taf Morgannwg University Health Board') { this.population = 449836; this.hospital = 'Cwm Taf Morgannwg University Health Board'; }
      if (this.country === 'Wales' && this.region === 'Welsh Health Board' && value === 'Aneurin Bevan University Health Board') { this.population = 598194; this.hospital = 'Aneurin Bevan University Health Board'; }
      if (this.country === 'Wales' && this.region === 'Welsh Health Board' && value === 'Cardiff and Vale University Health Board') { this.population = 504497; this.hospital = 'Cardiff and Vale University Health Board'; }
    },
  },
  methods: {
    setPopulation() {
      this.$store.commit('setHospital', this.hospital);
      this.$router.push('/drug-timeframe');
    },
    sendPage() {
      this.$store.commit('setPage', 'population');
    },
    checkPopulationType() {
      console.log('foo');
      console.log(this.$store.state.manualPopulation);
      if (this.$store.state.manualPopulation === true) {
        this.$store.commit('setPopulation', 0);
        this.$store.commit('setPopulationType', false);
        this.$store.commit('setCountry', '');
        this.$store.commit('setRegion', '');
        this.$store.commit('setOrganisation', '');
      }
    },
  },
  mounted() {
    this.checkPopulationType();
    this.sendPage();
  },
};
</script>
